function sanitizeNextUrl(nextUrl) {
  if (!nextUrl) {
    return '/'
  }

  if (typeof nextUrl !== 'string') {
    return '/'
  }

  if (nextUrl[0] !== '/') {
    return '/'
  }

  if (nextUrl.indexOf('.') >= 0) {
    return '/'
  }

  return nextUrl
}

export default {
  sanitizeNextUrl
}
