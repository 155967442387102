<template>
  <v-app>
    <v-main>
      <v-container fill-height="fill-height">
        <v-layout align-center="align-center" justify-center="justify-center">
          <v-flex id="app-container" class="xl4 lg6 md8 sm10">
            <v-img src="@/assets/logo_sleipner.png" max-height="100" contain />
            <v-card outlined>
              <router-view/>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import config from '@/config'
import AuthenticationService from '@/services/AuthenticationService'

export default {
  name: 'app',
  async mounted() {
    if (this.$router.currentRoute.name !== 'Authenticated') {
      let session_uuid = this.$cookie.get(config.SESSION_COOKIE_NAME)
      if (session_uuid) {
        let response
        try {
          response = await AuthenticationService.checkSessionUuid(session_uuid)
        } catch {
          this.$cookie.delete(config.SESSION_COOKIE_NAME)
          return
        }

        if (!response || !response.data.success) {
          this.$cookie.delete(config.SESSION_COOKIE_NAME)
          return
        }

        this.$router.push('/authenticated/')
      }
    }
  }
}
</script>

<style lang="sass">
a
  color: #373D42 !important
</style>
