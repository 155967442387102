<template>
  <div>
    <v-card-title class="text-h5">
      Reset your password
    </v-card-title>

    <v-progress-linear v-if="loading" indeterminate color="#CFDFDF"></v-progress-linear>

    <v-card-text>
      <!-- step 1 -->
      <v-form v-model="validEmailForm" @submit.prevent="onSubmitEmail" v-if="state == 'email'">
        <v-text-field
          v-model="email"
          prepend-icon="mdi-email"
          label="Email"
          type="email"
          :rules="rules.email"
          required
        ></v-text-field>

        <div class="mt-4">
          <v-btn to="/">
            <v-icon left>
              mdi-chevron-left
            </v-icon>
            Back
          </v-btn>

          <v-btn class="ml-2 float-right" type="submit" color="#373D42" dark>Next</v-btn>
        </div>
      </v-form>
      <!-- step 2 -->
      <v-form v-model="validCodeForm" @submit.prevent="onSubmitCode" v-else-if="state == 'code'">
        <p>
          We have sent you an email with a six-digit code to <em>{{ email }}</em>. Make sure to check your spam
          folder if it doesn't appear in your inbox.
        </p>
        <p>
          Fill in your code below and set a new password.
        </p>

        <v-text-field
          v-model="code"
          prepend-icon="mdi-numeric"
          label="Code"
          type="number"
          :rules="rules.code"
          required
        ></v-text-field>
        <v-text-field
          v-model="newPassword"
          prepend-icon="mdi-lock"
          label="New password"
          type="password"
          :rules="rules.password"
          required
        ></v-text-field>

        <div class="mt-4">
          <v-btn to="/">
            <v-icon left>
              mdi-chevron-left
            </v-icon>
            Back
          </v-btn>

          <v-btn class="ml-2 float-right" type="submit" color="#373D42" dark>Reset password</v-btn>
        </div>
      </v-form>
      <!-- step 3 -->
      <div v-else-if="state == 'success'">
        <p>
          Your password has been successfully reset! <router-link to="/">Click here</router-link> to sign in.
        </p>
      </div>

      <v-alert v-if="errorMessage" type="error" class="mt-4">{{ errorMessage }}</v-alert>
    </v-card-text>
  </div>
</template>

<script>
import AuthenticationService from '@/services/AuthenticationService'

const GENERIC_ERROR = 'Unknown error. Please refresh the page and try again'

export default {
  name: 'ForgotPassword',
  data() {
    return {
      email: '',
      code: '',
      newPassword: '',

      validCodeForm: false,
      validEmailForm: false,

      errorMessage: '',
      rules: {
        email: [
          v => (v || '').length > 0 || 'E-mail is required',
          v => /.+@.+/.test(v) || 'E-mail must be valid'
        ],
        password: [
          v => (v || '').length > 0 || 'Password is required',
          v => (v || '').length > 7 || 'Password needs to be at least 8 characters long',
        ],
        code: [
          v => (v || '').length > 0 || 'Please type in the code sent to your e-mail',
          v => (v || '').length == 6 || 'The code should be exactly 6 digits',
        ]
      },

      loading: false,
      state: 'email'
    }
  },
  methods: {
    async onSubmitCode() {
      this.errorMessage = ''

      if (!this.validCodeForm) {
        return
      }

      let response
      this.loading = true
      try {
        response = await AuthenticationService.resetPassword({
          code: this.code,
          new_password: this.newPassword
        })
      } catch (e) {
        this.errorMessage = e && e.message ? e.message : GENERIC_ERROR
        return
      } finally {
        this.loading = false
      }

      if (!response || !response.data.success) {
        this.errorMessage = response && response.data.error
          ? response.data.error
          : GENERIC_ERROR
        return
      }

      this.state = 'success'
    },
    async onSubmitEmail() {
      this.errorMessage = ''

      if (!this.validEmailForm) {
        return
      }

      let response
      this.loading = true
      try {
        response = await AuthenticationService.forgotPassword({
          email: this.email
        })
      } catch (e) {
        this.errorMessage = e && e.message ? e.message : GENERIC_ERROR
        return
      } finally {
        this.loading = false
      }

      if (!response || !response.data.success) {
        this.errorMessage = response && response.data.error
          ? response.data.error
          : GENERIC_ERROR
        return
      }

      this.state = 'code'
    }
  }
}
</script>

<style lang="sass">
.v-form .v-icon
    color: #083E48 !important

</style>
