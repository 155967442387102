<template>
  <div>
    <v-card-title class="text-h5"> Create new user </v-card-title>

    <v-progress-linear
      v-if="loading"
      indeterminate
      color="#CFDFDF"
    ></v-progress-linear>

    <v-card-text>
      <!-- step 1 -->
      <v-form
        v-model="validEmailForm"
        @submit.prevent="onSubmitEmail"
        v-if="state == 'email'"
      >
        <p>
          Enter the email address we have listed in our ERP system for your
          company.
        </p>
        <p>
          If you do not know which address we have listed for your company, or
          otherwise need help, you can
          <a
            href="https://www.sleipnergroup.com/support/contact-form"
            target="_blank"
            >contact us here.</a
          >
        </p>
        <v-text-field
          v-model="email"
          prepend-icon="mdi-email"
          label="Email"
          type="email"
          :rules="rules.email"
          required
        ></v-text-field>

        <div class="mt-4">
          <v-btn to="/">
            <v-icon left> mdi-chevron-left </v-icon>
            Back
          </v-btn>

          <v-btn class="ml-2 float-right" type="submit" color="#373D42" dark
            >Next</v-btn
          >
        </div>
      </v-form>
      <!-- step 2 -->
      <v-form
        v-model="validCodeForm"
        @submit.prevent="onSubmitCode"
        v-else-if="state == 'code'"
      >
        <p>
          We have sent you an email with a six-digit code to <em>{{ email }}</em
          >. Make sure to check your spam folder if it doesn't appear in your
          inbox.
        </p>
        <p>Fill in your code below so that we can verify your identity.</p>

        <v-text-field
          v-model="code"
          prepend-icon="mdi-numeric"
          label="Code"
          type="number"
          :rules="rules.code"
          required
        ></v-text-field>

        <div class="mt-4">
          <v-btn to="/">
            <v-icon left> mdi-chevron-left </v-icon>
            Back
          </v-btn>

          <v-btn class="ml-2 float-right" type="submit" color="#373D42" dark
            >Next</v-btn
          >
        </div>
      </v-form>
      <!-- step 3 -->
      <v-form
        v-model="validContactForm"
        @submit.prevent="onSubmitContact"
        v-else-if="state == 'contact'"
      >
        <p>
          Please fill in the form below to create your user. This user will be
          allowed to log into your web pages and mySite, and will have admin
          permissions.
        </p>

        <v-text-field
          v-model="contact.name"
          label="Name"
          type="text"
          :rules="rules.name"
          required
        ></v-text-field>

        <v-text-field
          v-model="contact.phone"
          label="Phone"
          type="text"
          :rules="rules.phone"
          required
        ></v-text-field>

        <v-text-field
          v-model="contact.email"
          label="Email"
          type="text"
          :rules="rules.email"
          required
        ></v-text-field>

        <v-text-field
          v-model="contact.position"
          label="Job position"
          type="text"
          :rules="rules.position"
          required
        ></v-text-field>

        <v-select
          :items="languages"
          item-value="code"
          item-text="label"
          v-model="contact.language"
          label="Preferred language"
          required
        ></v-select>

        <v-text-field
          v-model="contact.password"
          label="Password"
          type="password"
          :rules="rules.password"
          required
        ></v-text-field>

        <div class="mt-4">
          <v-btn to="/">
            <v-icon left> mdi-chevron-left </v-icon>
            Back
          </v-btn>

          <v-btn class="ml-2 float-right" type="submit" color="#373D42" dark
            >Create user</v-btn
          >
        </div>
      </v-form>
      <!-- step 4 -->
      <div v-else-if="state == 'success'">
        <p>
          A contact has been created with email <em>{{ contact.email }}</em
          >!
        </p>
        <p><router-link to="/">Click here</router-link> to sign in.</p>
      </div>

      <v-alert v-if="errorMessage" type="error" class="mt-4">{{
        errorMessage
      }}</v-alert>
    </v-card-text>
  </div>
</template>

<script>
import AuthenticationService from "@/services/AuthenticationService";

const GENERIC_ERROR = "Unknown error. Please refresh the page and try again";

export default {
  name: "CreateUser",
  data() {
    return {
      // Step 1
      email: "",
      // Step 2
      code: "",
      // Step 3
      contact: {
        name: "",
        phone: "",
        email: "",
        position: "",
        password: "",
        language: "",
      },

      validCodeForm: false,
      validEmailForm: false,
      validContactForm: false,

      errorMessage: "",
      rules: {
        name: [(v) => (v || "").length > 0 || "Name is required"],
        phone: [(v) => (v || "").length > 0 || "Phone is required"],
        position: [(v) => (v || "").length > 0 || "Position is required"],
        email: [
          (v) => (v || "").length > 0 || "E-mail is required",
          (v) => /.+@.+/.test(v) || "E-mail must be valid",
        ],
        password: [
          (v) => (v || "").length > 0 || "Password is required",
          (v) =>
            (v || "").length > 7 ||
            "Password needs to be at least 8 characters long",
        ],
        code: [
          (v) =>
            (v || "").length > 0 ||
            "Please type in the code sent to your e-mail",
          (v) => (v || "").length == 6 || "The code should be exactly 6 digits",
        ],
      },
      languages: [
        { code: "NO", label: "Norwegian" },
        { code: "EN", label: "English" },
      ],

      loading: false,
      state: "email",
    };
  },
  methods: {
    async onSubmitContact() {
      this.errorMessage = "";

      if (!this.validContactForm) {
        return;
      }

      let response;
      this.loading = true;
      try {
        response = await AuthenticationService.createUser({
          code: this.code,
          contact: this.contact,
        });
      } catch (e) {
        this.errorMessage = e && e.message ? e.message : GENERIC_ERROR;
        return;
      } finally {
        this.loading = false;
      }

      if (!response || !response.data.success) {
        this.errorMessage =
          response && response.data.error ? response.data.error : GENERIC_ERROR;
        return;
      }

      this.state = "success";
    },
    async onSubmitCode() {
      this.errorMessage = "";

      if (!this.validCodeForm) {
        return;
      }

      let response;
      this.loading = true;
      try {
        response = await AuthenticationService.createUserVerifyCode({
          code: this.code,
        });
      } catch (e) {
        this.errorMessage = e && e.message ? e.message : GENERIC_ERROR;
        return;
      } finally {
        this.loading = false;
      }

      if (!response || !response.data.success) {
        this.errorMessage =
          response && response.data.error ? response.data.error : GENERIC_ERROR;
        return;
      }

      this.state = "contact";
    },
    async onSubmitEmail() {
      this.errorMessage = "";

      if (!this.validEmailForm) {
        return;
      }

      let response;
      this.loading = true;
      try {
        response = await AuthenticationService.createUserVerifyEmail({
          email: this.email,
        });
      } catch (e) {
        this.errorMessage = e && e.message ? e.message : GENERIC_ERROR;
        return;
      } finally {
        this.loading = false;
      }

      if (!response || !response.data.success) {
        this.errorMessage =
          response && response.data.error ? response.data.error : GENERIC_ERROR;
        return;
      }

      this.state = "code";
    },
  },
};
</script>

<style lang="sass">
.v-form .v-icon
  color: #083E48 !important
</style>
