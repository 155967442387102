<template>
  <div>
    <v-card-title class="text-h5"> Sign in </v-card-title>

    <v-progress-linear
      v-if="loading"
      indeterminate
      color="#CFDFDF"
    ></v-progress-linear>

    <v-card-text>
      <div v-if="isAuthenticated">
        <v-alert color="#CFDFDF" icon="mdi-check-circle"
          >Login successful! You are being redirected back..</v-alert
        >
      </div>
      <v-form v-model="validForm" @submit.prevent="onSubmit" v-else>
        <v-text-field
          v-model="email"
          prepend-icon="mdi-email"
          label="Email"
          type="email"
          :rules="rules.email"
          required
        ></v-text-field>
        <v-text-field
          v-model="password"
          prepend-icon="mdi-lock"
          label="Password"
          type="password"
          :rules="rules.password"
          required
        ></v-text-field>

        <div class="actions mt-4">
          <v-btn type="submit" color="primary" dark>Sign in</v-btn>
          <v-btn
            to="/forgot-password/"
            class="ml-4 forgot-password"
            elevation="0"
            color="white"
            small
            >Forgot/create new password</v-btn
          >
        </div>
      </v-form>

      <v-alert v-if="errorMessage" type="error" class="mt-4">{{
        errorMessage
      }}</v-alert>

      <v-divider class="mt-2"></v-divider>

      <div class="mt-4 footer">
        If you're already a customer, but don't have an account, you can
        <router-link to="/create-user/">create a new account here</router-link>
      </div>
    </v-card-text>
  </div>
</template>

<script>
import AuthenticationService from "@/services/AuthenticationService";
import config from "@/config";
import sanitize from "@/utils/sanitize";

const GENERIC_ERROR = "Unknown error. Please refresh the page and try again";

export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",

      validForm: false,
      errorMessage: "",
      rules: {
        email: [
          (v) => (v || "").length > 0 || "E-mail is required",
          (v) => /.+@.+/.test(v) || "E-mail must be valid",
        ],
        password: [
          (v) => (v || "").length > 0 || "Password is required",
          // v => (v || '').length > 7 || 'Password needs to be at least 8 characters long',
        ],
      },

      loading: false,
      isAuthenticated: false,
      next: null,
      service: null,
    };
  },
  mounted() {
    let uri = window.location.search.substring(1);
    let params = new URLSearchParams(uri);

    this.next = sanitize.sanitizeNextUrl(params.get("next") || "/");
    this.service = params.get("service") || "web";
  },
  methods: {
    async onSubmit() {
      this.errorMessage = "";

      if (!this.validForm) {
        return;
      }

      let response;
      this.loading = true;
      try {
        response = await AuthenticationService.authenticate({
          email: this.email,
          password: this.password,
          next: this.next,
        });
      } catch (e) {
        this.errorMessage = e && e.message ? e.message : GENERIC_ERROR;
        return;
      } finally {
        this.loading = false;
      }

      if (!response || !response.data.success) {
        this.errorMessage =
          response && response.data.error ? response.data.error : GENERIC_ERROR;
        return;
      }

      this.onAuthenticated(response.data);
    },
    async onAuthenticated(data) {
      this.setSessionCookie(data.session_uuid);

      this.isAuthenticated = true;

      let url;
      if (this.service === "mysite") {
        url = config.MYSITE_URL;
        if (this.next) {
          url += "?next=" + this.next;
        }
      } else if (this.service === "web") {
        let response = await AuthenticationService.sanitizeRedirect(
          data.session_uuid,
          this.next || "/"
        );
        if (!response || !response.data || !response.data.success) {
          this.isAuthenticated = false;
          return;
        }
        url = response.data.url;
      } else {
        this.isAuthenticated = false;
        this.errorMessage =
          "Something went wrong and we couldn't redirect you back";
        return;
      }

      window.location.href = url;

      this.loading = true;
    },
    setSessionCookie(session_uuid) {
      this.$cookie.set(config.SESSION_COOKIE_NAME, session_uuid, {
        domain: config.SESSION_COOKIE_DOMAIN,
        secure: config.SESSION_COOKIE_SECURE,
        samesite: "Lax",
        expires: "7D",
      });
    },
  },
};
</script>

<style lang="sass">
.forgot-password
  color: #373D42 !important
  text-transform: none !important

.footer
  color: #333

.v-form .v-icon
  color: #083E48 !important
</style>
