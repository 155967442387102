<template>
  <div>
    <v-card-title class="text-h5">
      You are currently signed in
    </v-card-title>

    <v-progress-linear v-if="loading" indeterminate color="#CFDFDF"></v-progress-linear>

    <v-card-text>
      <div v-if="redirecting">
        <v-alert color="#CFDFDF" icon="mdi-check-circle">You are being redirected back..</v-alert>
      </div>
      <div v-else-if="sessionData">
        <p>
          Hello {{ sessionData.contact.name }}, you are currently signed in.
        </p>

        <p>
          <a href="#" @click="logOut()">Click here to log out</a>
        </P>

        <p>
          You can visit our <a :href="webUrl">website</a> or <a :href="mySiteUrl">mySite</a>.
        </p>
      </div>
    </v-card-text>
  </div>
</template>

<script>
import AuthenticationService from '@/services/AuthenticationService'
import config from '@/config'
import sanitize from '@/utils/sanitize'

export default {
  name: 'Authenticated',
  data() {
    return {
      service: '',
      next: '',
      sessionData: null,
      loading: false,
      redirecting: false,
      webUrl: config.WEB_URL,
      mySiteUrl: config.MYSITE_URL
    }
  },
  async mounted() {
    let session_uuid = this.$cookie.get(config.SESSION_COOKIE_NAME)
    if (!session_uuid) {
      this.$cookie.delete(config.SESSION_COOKIE_NAME)
      this.$router.push('/')
      return
    }

    this.loading = true

    let response
    try {
      response = await AuthenticationService.checkSessionUuid(session_uuid)
    } catch {
      this.$cookie.delete(config.SESSION_COOKIE_NAME)
      this.$router.push('/')
      return
    } finally {
      this.loading = false
    }

    if (!response || !response.data.success) {
      this.$cookie.delete(config.SESSION_COOKIE_NAME)
      this.$router.push('/')
      return
    }

    this.sessionData = response.data

    let uri = window.location.search.substring(1)
    let params = new URLSearchParams(uri)

    this.next = sanitize.sanitizeNextUrl(params.get('next') || '/')
    this.service = params.get('service')

    if (this.service) {
      this.redirectToExternal()
    }
  },
  methods: {
    async redirectToExternal() {
      this.redirecting = true

      let url
      if (this.service === 'mysite') {
        url = config.MYSITE_URL
        if (this.next) {
          url += '?next=' + this.next
        }
      }
      else if (this.service === 'web') {
        let session_uuid = this.$cookie.get(config.SESSION_COOKIE_NAME)
        let response = await AuthenticationService.sanitizeRedirect(session_uuid, this.next || '/')
        if (!response || !response.data || !response.data.success) {
          this.redirecting = false
          return
        }
        url = response.data.url
      }
      else {
        this.redirecting = false
        return
      }

      window.location.href = url;

      this.loading = true
    },
    setSessionCookie(session_uuid) {
      this.$cookie.set(config.SESSION_COOKIE_NAME, session_uuid, {
        domain: config.SESSION_COOKIE_DOMAIN,
        secure: config.SESSION_COOKIE_SECURE,
        samesite: 'Lax',
        expires: '7D'
      })
    },
    async logOut() {
      let session_uuid = this.$cookie.get(config.SESSION_COOKIE_NAME)
      if (session_uuid) {
        await AuthenticationService.deleteSessionUuid(session_uuid)
        this.$cookie.delete(config.SESSION_COOKIE_NAME)
      }
      this.$router.push('/')
    }
  }
}
</script>

<style lang="sass">
.v-form .v-icon
    color: #083E48 !important
</style>
