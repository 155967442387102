import Api from '@/services/Api'

export default {
  /* Login */
  authenticate(data) {
    return Api().post('/web/auth/login/', data)
  },

  /* Reset password */
  forgotPassword(data) {
    return Api().post('/web/auth/forgot-password/', data)
  },
  resetPassword(data) {
    return Api().post('/web/auth/reset-password/', data)
  },

  /* Create user */
  createUserVerifyEmail(data) {
    return Api().post('/web/auth/user/verify-email/', data)
  },
  createUserVerifyCode(data) {
    return Api().post('/web/auth/user/verify-code/', data)
  },
  createUser(data) {
    return Api().post('/web/auth/user/create/', data)
  },

  /* Check */
  checkSessionUuid(sessionUuid) {
    return Api().get('/web/auth/session/' + sessionUuid)
  },

  /* Delete/Logout */
  deleteSessionUuid(sessionUuid) {
    return Api()['delete']('/web/auth/session/' + sessionUuid)
  },

  /* Sanitize redirect */
  sanitizeRedirect(sessionUuid, next) {
    return Api().get('/web/auth/session/' + sessionUuid + '/sanitize-redirect/?next=' + (next || '/'))
  }
}
